import { AxiosInstance, AxiosRequestConfig } from 'axios';

class Branchs {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getBranchs() {
    return this.instance.get('/global/branchs');
  }

  getBranchsUser() {
    return this.instance.get('/global/branchs/user');
  }
}

const _Branchs = (instance: AxiosInstance) => new Branchs(instance);

export default _Branchs;
