/* eslint-disable camelcase */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import api from '.';
import RoutesEnum from '../enum/RoutesEnum';
import { history } from 'src/App';
import { STORAGE } from '../config/storage';

declare module 'axios' {
  export interface AxiosRequestConfig {
    handlerEnabled: boolean;
  }
}

export interface IConfigProps {
  api_url: string;
  has_user_header: boolean;
}



export class Config {
  axios: AxiosInstance;

  constructor({ api_url }: IConfigProps) {
    if (!api_url) throw new Error('Missing api_url');
    this.axios = this.create(api_url);

    let isRefreshing = false
    let failedRequestQueue: {
      onSuccess: (token: string, atoken: string) => void
      onFailure: (err: AxiosError) => void
    }[] = []

    this.axios.interceptors.response.use(response => response,
      error => {
        const isUnauthorized = error.response?.status === 401
        if (isUnauthorized) {
          const refreshToken = localStorage.getItem(STORAGE.REFRESH);
          const originalConfig = error.config

          if (!isRefreshing && refreshToken) {
            isRefreshing = true
            api.auth().refreshToken(refreshToken).then(response => {
              const { token, atoken, refreshToken } = response.data
              localStorage.setItem(STORAGE.TOKEN, token || '');
              localStorage.setItem(STORAGE.TOKEN_ADMIN, atoken || '');
              localStorage.setItem(STORAGE.REFRESH, refreshToken || '');

              api.instance.defaults.headers.Authorization = `Bearer ${token}`
              api.instance.defaults.headers.AuthorizationAdmin = `Bearer ${atoken}`

              failedRequestQueue.forEach((request: { onSuccess: (token: string, atoken: string) => any; }) => request.onSuccess(token, atoken))
              failedRequestQueue = []
            })
              .catch(err => {
                failedRequestQueue.forEach((request: { onFailure: (err: any) => any; }) => request.onFailure(err))
                failedRequestQueue = []
                history.push(RoutesEnum.LOGIN_ROUTE);
              })
              .finally(() => {
                isRefreshing = false
              })
          }

          return new Promise((resolve, reject) => {
            failedRequestQueue.push({
              onSuccess: (token: string, atoken: string) => {
                originalConfig.headers.Authorization = `Bearer ${token}`
                originalConfig.headers.AuthorizationAdmin = `Bearer ${atoken}`
                resolve(axios(originalConfig))
              },
              onFailure: (err: AxiosError) => {
                reject(err)
              }
            })
          })
        }
        return Promise.reject(error)
      })
  }

  create(apiUrl: string) {
    const userData = localStorage.getItem('@pdiOnline/data');
    return axios.create({
      baseURL: apiUrl,
      timeout: 180000,
      headers: {
        'Content-Type': 'application/json',
        ...(userData ? { user: JSON.parse(userData).email } : {}),
        // Authorization: api_key,
      },
    } as AxiosRequestConfig);
  }

  instance() {
    return this.axios;
  }
}

const config = (config: IConfigProps) => new Config(config);

export default config;
