import React, { lazy } from 'react';
import RoutesEnum, { routes } from 'src/enum/RoutesEnum';

export const authGuardRoutes = [
  {
    exact: true,
    path: routes.v2.requests.edit,
    component: lazy(() => import('src/pages/NewRequest')),
  },
  {
    exact: true,
    path: routes.v2.requests.view,
    component: lazy(() => import('src/pages/ViewVehicle')),
  },
  {
    exact: true,
    path: routes.v2.requests.viewDispatched,
    component: lazy(() => import('src/pages/ViewVehicleDispatched')),
  },
  {
    exact: true,
    path: RoutesEnum.USERS_ROUTE,
    component: lazy(() => import('src/components/authenticate')),
  },
  {
    exact: true,
    path: RoutesEnum.REQUEST_PAGE_ROUTE,
    component: lazy(() => import('src/pages/vehiclesListClientPage')),
  },
  {
    exact: true,
    path: RoutesEnum.DASHBOARD_ROUTE,
    component: lazy(() => import('src/pages/dashboard')),
  },
  {
    exact: true,
    path: RoutesEnum.LAST_MILE_TRACK_ROUTE,
    component: lazy(() => import('src/pages/lastMileTrack')),
  },
  {
    exact: true,
    path: RoutesEnum.OPERATIONAL_METRICS_PAGE_ROUTE,
    component: lazy(() => import('src/pages/operationalMetricsPage')),
  },
  {
    exact: true,
    path: RoutesEnum.STOCK_PAGE_ROUTE,
    component: lazy(() => import('src/pages/stockPage')),
  },
  {
    exact: true,
    path: RoutesEnum.VIEW_PROFILE_ROUTE,
    component: lazy(() => import('src/pages/profilePage')),
  },
  {
    exact: true,
    path: RoutesEnum.SERVICE_CLIENT_ROUTE,
    component: lazy(() => import('src/pages/serviceClientPage')),
  },
  {
    exact: true,
    path: RoutesEnum.FAQ_ROUTE,
    component: lazy(() => import('src/pages/faq')),
  },
  {
    exact: true,
    path: RoutesEnum.FAQ_SECTION_ROUTE,
    component: lazy(() => import('src/pages/faq-section')),
  },
]