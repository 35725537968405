import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { IRequestVehicleData } from 'src/context/hooks/useListRequest';
import { IFilterVehicles } from 'src/context/hooks/useVehicle';

interface IFilterVehicle {
  status?: string;
  reduced?: any;
}

interface IValueDispatched {
  vehicles: string[];
}

interface IFilterCargaAndArquivedStatus {
  client?: string;
  search?: string;
}
interface IFilterExport {
  perPage: string;
  reduced: string;
}

class VehiclesSada {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getRequestsVehiclesTable(idRequest: string | undefined, filter?: IFilterVehicle) {
    return this.instance.get(`/admin/request/${idRequest}/vehicles`, {
      params: filter,
    } as AxiosRequestConfig);
  }

  setNewVehicles(idRequest: string, values: IRequestVehicleData) {
    return this.instance.post<any[]>(`/request/${idRequest}/vehicles`, values);
  }

  updateVehicle(idRequest: string, idVehicle: string, values: any) {
    return this.instance.put(`/admin/request/${idRequest}/vehicles/${idVehicle}`, values);
  }

  getPendencies(idRequest: string) {
    return this.instance.get(`/admin/request/${idRequest}/requestEdit`);
  }

  confirmAlterationPendencies(idRequest: string, idEditRequest: string) {
    return this.instance.patch(`/admin/request/${idRequest}/requestEdit/${idEditRequest}`);
  }

  chassiVinculation(idRequest: string) {
    return this.instance.patch<{ [key: string]: number }>(`/admin/request/${idRequest}/chassis`);
  }

  getVehicle(idRequest: string | undefined, idVehicle: string | undefined) {
    return this.instance.get(`/request/${idRequest}/vehicles/${idVehicle}`);
  }

  deleteVehicle(idRequest: string, idVehicle: string) {
    return this.instance.delete(`/request/${idRequest}/vehicles/${idVehicle}`);
  }

  setVehicleDispatched(idRequest: string, values: IValueDispatched) {
    return this.instance.patch(`/admin/request/${idRequest}/expedicao`, values);
  }

  getVehiclesCargaStatus(filter: IFilterCargaAndArquivedStatus) {
    return this.instance.get('/admin/vehicles/finalizado', {
      params: filter,
    } as AxiosRequestConfig);
  }

  getVehiclesArquivedStatus(filter: IFilterCargaAndArquivedStatus) {
    return this.instance.get('/admin/vehicles/arquivado', {
      params: filter,
    } as AxiosRequestConfig);
  }

  getVehiclesDispatched(filter: IFilterCargaAndArquivedStatus) {
    return this.instance.get('/admin/vehicles/expedido', {
      params: filter,
    } as AxiosRequestConfig);
  }

  exportVehicles(idRequest: string, filter: IFilterExport) {
    return this.instance.get(`/admin/request/${idRequest}/vehicles`, {
      params: filter,
    } as AxiosRequestConfig);
  }

  vehiclesEmbarque(filter?: IFilterCargaAndArquivedStatus) {
    return this.instance.get('/admin/vehicles/embarque', {
      params: filter,
    } as AxiosRequestConfig);
  }

  getVehiclesV2(filter?: IFilterVehicles) {
    return this.instance.get('/admin/vehicles-v2', {
      params: filter,
    } as AxiosRequestConfig);
  }
}

const _VehicleSada = (instance: AxiosInstance) => new VehiclesSada(instance);

export default _VehicleSada;
