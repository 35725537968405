import React from 'react';
import { Box, Typography } from '@material-ui/core';

function NpsSuccess() {
  return (
    <Box className="flex flex- gap-4">
      <img src="/assets/success-man.svg" alt="Success Man" width="100px" />
      <Box className="flex flex-col">
        <Typography variant="h6" className="font-medium">
          Obrigado!
        </Typography>
        <Typography variant="subtitle1">
          Sua opinião é importante para te atendermos melhor a cada dia.
        </Typography>
      </Box>
    </Box>
  );
}

export { NpsSuccess }
