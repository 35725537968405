import { safeRequestThrowable } from '../commons';
import { RestResult } from '../commons/types';
import {
  NpsOverviewResultsResponse,
  NpsRequest,
  NpsOverviewRequest,
  NpsResultsResponse,
  NpsResultsRequest,
} from './types';

export const sendNpsMeasurement = async (request: NpsRequest): Promise<RestResult<void>> => {
  const result = await safeRequestThrowable({
    method: 'post',
    url: '/nps',
    data: request,
  });

  return {
    ...result,
    data: result.data?.data,
  };
};

export const getNpsResults = async (
  filter?: Partial<NpsResultsRequest>,
): Promise<RestResult<NpsResultsResponse>> => {
  const result = await safeRequestThrowable({
    method: 'get',
    url: '/admin/nps/dashboard',
    params: filter,
  });

  return {
    ...result,
    data: result.data?.data,
  };
};

export const sendNpsTracking = async (): Promise<RestResult<void>> => {
  const result = await safeRequestThrowable({
    method: 'get',
    url: '/nps/tracking/view',
  });

  return {
    ...result,
    data: result.data?.data,
  };
};

export const getNpsAnswers = async (
  filter?: Partial<NpsOverviewRequest>,
): Promise<RestResult<NpsOverviewResultsResponse>> => {
  const result = await safeRequestThrowable({
    method: 'get',
    url: '/admin/nps/answers',
    params: filter,
  });

  return {
    ...result,
    data: result.data.data,
  };
};
