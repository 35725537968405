import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {  MuiThemeProvider } from '@material-ui/core';
import Routes from './routes';
import { AuthProvider } from './context';
import './styles/global.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';
import AlertBox from './components/AlertBoxGlobal';
import ScrollToTop from './ScrollToTop';
import OnTheScreenLoading from './components/OnTheScreenLoading';
import { ToastContainer } from 'react-toastify';
import { theme } from './theme';
import { inititalizeAnalytics } from './utils/analytics'

export const history = createBrowserHistory();

function App() {
  inititalizeAnalytics()
  return (
    <MuiThemeProvider theme={theme}>
      <Router history={history}>
        <AuthProvider>
          <ScrollToTop>
            <ToastContainer />
            <OnTheScreenLoading />
            <Routes />
          </ScrollToTop>
          <AlertBox />
        </AuthProvider>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
