import apiFactory from './api';

const api = apiFactory({
  api_url: process.env.REACT_APP_URL_API || 'http://localhost:3333/',
  // http://localhost:3333/
  // http://pdionline.sada.com.br:3333/
  // https://api-pdi.sada.com.br/
  has_user_header: true,
});

export default api;
