import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import RoutesEnum from 'src/enum/RoutesEnum';
import { Context } from 'src/context';

function AuthGuard({ children }: any) {
  const { authenticated } = useContext(Context);

  if (!authenticated) {
    return <Redirect to={RoutesEnum.LOGIN_ROUTE} />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any,
};

// @ts-ignore
export default AuthGuard;
