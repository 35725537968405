import { useCallback, useState } from 'react';

export interface IAlert {
  message?: string;
  backgroundColor?: string;
  active: boolean;
}

export default function useAlertGlobal() {
  const [alertBox, setAlertBox] = useState<IAlert | null>({ message: '', active: false });

  const handleCallAlert = useCallback(
    (message: string, backgroundColor?: string, active = true) => {
      setAlertBox({ message, backgroundColor, active });
      setTimeout(() => {
        setAlertBox({ message: '', active: false });
      }, 6000);
    },
    [],
  );

  return { handleCallAlert, alertBox };
}
