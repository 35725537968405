import React, { Fragment, Suspense, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import RoutesEnum from 'src/enum/RoutesEnum';
import AuthGuard from 'src/components/AuthGuard';
import AdminGuard from 'src/components/AdminGuard';
import Layout from 'src/components/Layout/Default';
import SplashScreen from 'src/components/SpashScreen';
import { noAuthGuardRoutes } from './types/no-auth-guard-routes';
import { authGuardRoutes } from './types/auth-guard-routes';
import { authGuardAdminRoutes } from './types/auth-guard-admin-routes';
import { sendEventAnalytics } from '../utils/analytics';
import { TYPE_EVENTS_GA } from '../utils/analytics/type-events-ga';

const routesConfig = [
  ...noAuthGuardRoutes,
  {
    guard: AuthGuard,
    layout: Layout,
    routes: [
      ...authGuardRoutes,
      {
        guard: AdminGuard,
        path: RoutesEnum.ADMIN_ROUTE,
        routes: [
          ...authGuardAdminRoutes
        ],
      },
    ],
  },
];

const renderRoutes = (routes: any) =>
  routes ? (
    <Suspense fallback={<SplashScreen />}>
      <Switch>
        {routes.map((route: any, i: number) => {
          const Layout = route.layout || Fragment;
          const Guard = route.guard || Fragment;
          const Component = route.component || Fragment;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  const history = useHistory();

  useEffect(() => {
    history.listen((location) => {
      sendEventAnalytics(TYPE_EVENTS_GA.PAGEVIEW, location.pathname)
    });
  }, []);

  return renderRoutes(routesConfig);
}

export default Routes;
