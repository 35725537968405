import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { IRequestVehicleData } from 'src/context/hooks/useListRequest';
import { ICheckChassi, IFilterVehicles } from 'src/context/hooks/useVehicle';
import { IFilterVehicle } from '../../context/hooks/useRequestVehicles';

class Vehicles {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getRequestsVehiclesList() {
    return this.instance.get('/global/vehicles');
  }

  getRequestsVehiclesTable(idRequest: string | undefined, filter?: IFilterVehicle) {
    return this.instance.get(`/request/${idRequest}/vehicles`, {
      params: filter,
    } as AxiosRequestConfig);
  }

  setNewVehicles(idRequest: string, values: IRequestVehicleData) {
    return this.instance.post(`/request/${idRequest}/vehicles`, values);
  }

  updateVehicle(idRequest: string, idVehicle: string, values: any) {
    return this.instance.put(`/request/${idRequest}/vehicles/${idVehicle}`, values);
  }

  getVehicle(idRequest: any, idVehicle: any) {
    return this.instance.get(`/request/${idRequest}/vehicles/${idVehicle}`);
  }

  deleteVehicle(idRequest: string, idVehicle: string) {
    return this.instance.delete(`/request/${idRequest}/vehicles/${idVehicle}`);
  }

  setNewFileVehicle(idRequest: string, idVehicle: string, data: any) {
    return this.instance.patch(`/request/${idRequest}/files/${idVehicle}`, data);
  }

  setNewVehicle(data: any) {
    return this.instance.post('/global/vehicles', data);
  }

  vehiclesEmbarque(filter?: any) {
    return this.instance.get('/vehicles/embarque', {
      params: filter,
    } as AxiosRequestConfig);
  }

  doubleCheckChassi(data: ICheckChassi) {
    return this.instance.post<string[]>('/vehicles/double-check', data);
  }

  getVehiclesV2(filter?: IFilterVehicles) {
    return this.instance.get('/vehicles-v2', {
      params: filter,
    } as AxiosRequestConfig);
  }
}

const _Vehicle = (instance: AxiosInstance) => new Vehicles(instance);

export default _Vehicle;
