import { Close, Menu } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { IMenuItem } from '../..';
import { useLocation } from 'react-router-dom';
import { Context } from 'src/context';
import { v4 as uuidv4 } from 'uuid';
import { useHasUserPermission } from 'src/context/hooks/useHasUserPermission';
import useBranchTypeModel from 'src/context/hooks/useBranchType';
import RoutesEnum from 'src/enum/RoutesEnum';
import { useHasEmailPermission } from 'src/context/hooks/useHasEmailPermission';
interface Props {
  items: Array<IMenuItem[]>;
  isOpen: boolean;
  setOpen(state: boolean): void;
}

const SidebarItem = ({
  item,
  isOpen,
  isAdmin,
}: {
  item: IMenuItem;
  isOpen: boolean;
  isAdmin: boolean | undefined;
}) => {
  const location = useLocation();
  const Icon = item.icon;

  const currentRouteSelected = location.pathname;

  const textColor = isAdmin ? 'text-[#0F5977]' : 'text-greenSada';
  const iconColor = isAdmin ? '#0F5977' : '#00b838';
  const backgroundColor = isAdmin ? 'bg-[#DFECF9]' : 'bg-[#D9F0DB]';

  return (
    <div
      key={item.name}
      onClick={item.onClick}
      className={`${currentRouteSelected === item.page ? backgroundColor : 'hover:bg-[#F5F5F5]'
        } flex gap-4 items-center justify-start w-full h-14 px-4 cursor-pointer`}>
      <Icon style={{ color: currentRouteSelected === item.page ? iconColor : '#00000099' }} />
      <p
        className={`font-medium overflow-hidden min-w-[210px] ${currentRouteSelected === item.page ? textColor : 'text-greySada'
          }`}>
        {isOpen && item.name}
      </p>
    </div>
  );
};

export const Sidebar: React.FC<Props> = ({ items, isOpen, setOpen }) => {
  const { userAuth } = useContext(Context);
  const { hasUserPermission } = useHasUserPermission();
  const { isBranchIgarape } = useBranchTypeModel();
  const { hasEmailPermission } = useHasEmailPermission();

  const showIgarapeMenus = (item: IMenuItem): boolean => {
    if (!isBranchIgarape())
      return true

    return true
  }

  if (!userAuth) return null;

  const isAdmin = userAuth.admin;

  const sidebarItems = items[0].filter((item) => item.admin === isAdmin && hasUserPermission(item.page) && showIgarapeMenus(item) && hasEmailPermission(item.emailsPermissions));

  return (
    <aside
      className={`fixed mt-16 h-screen z-40 bg-white duration-200 ${isOpen ? 'w-64' : 'w-16'
        } border-r-[1px] border-b-[##DBDDE2]`}>
      <div
        onClick={() => setOpen(!isOpen)}
        className="flex w-full h-14 items-center justify-between cursor-pointer p-4 border-b-[1px] border-b-[##DBDDE2]">
        {isOpen ? (
          <>
            <h2 className="text-xl font-medium">Menu</h2>
            <Close className="text-gray-500" />
          </>
        ) : (
          <Menu className="text-greySada" style={{ fontSize: 28 }} />
        )}
      </div>

      {sidebarItems.map((item) => (
        <SidebarItem item={item} isOpen={isOpen} key={uuidv4()} isAdmin={item.admin} />
      ))}

    </aside>
  );
};
