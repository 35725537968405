import { AxiosInstance } from 'axios';
import { IRegisterClients } from 'src/context/hooks/useGlobalHooks';
import { IFilterClients } from './index.types';

class Clients {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getClients(filter?: IFilterClients) {
    return this.instance.get(`/clients`, { handlerEnabled: false, params: filter });
  }

  getClientsAccess(branch?: string, rentals?: string[]) {
    return this.instance.post('/clients/access/list', { branch, rentals });
  }

  getClient(id: string) {
    return this.instance.get(`/clients/${id}`);
  }

  register(values: IRegisterClients) {
    return this.instance.post('/clients', values);
  }

  editClient(id: string, values: IRegisterClients) {
    return this.instance.put(`/clients/${id}`, values);
  }

  deleteClient(id: string) {
    return this.instance.delete(`/clients/${id}`);
  }
}

const _Clients = (instance: AxiosInstance) => new Clients(instance);

export default _Clients;
