import React from 'react';
import { CircularProgress } from '@material-ui/core';
import useServicesContext from 'src/context/hooks/useServicesContext';

export default function OnTheScreenLoading() {
  const { loadingEditVehicle } = useServicesContext();

  const styledAlert: any = {
    position: 'fixed',
    top: '0%',
    zIndex: 80,
  };

  if (!loadingEditVehicle) return null;

  return (
    <div style={styledAlert}>
      <div className="w-screen h-screen bg-[rgba(0,0,0,0.5)] flex justify-center z-20">
        <div className="self-center pl-44">
          <CircularProgress style={{ color: 'white' }} />
        </div>
      </div>
    </div>
  );
}
