import { createTheme } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';

export const theme = createTheme(
  {
    palette: {
      primary: {
        light: 'rgba(63,182,75,0.3)',
        main: '#3FB54B',
        dark: '#318739',
        contrastText: '#fff',
      },
      secondary: {
        light: '#4c9beb',
        main: '#2687E9',
        dark: '#167fe7',
        contrastText: '#fff',
      },
      error: {
        light: '#c5334e',
        main: '#B00020',
        dark: '#8a041d',
        contrastText: '#fff',
      },
    },
    overrides: {
      MuiMenuItem: {
        root: {
          '&$selected': {
            backgroundColor: '#DDF5E4',
            '&:hover': {
              backgroundColor: '#DDF5E4'
            }
          },
          '&:hover': {
            backgroundColor: '#CAF0D5'
          }
        },
        
      },
    }
  },
  ptBR,
);
