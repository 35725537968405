import React, { useContext } from 'react';
import { Context } from 'src/context';
import { RoutesPermission } from 'src/enum/RoutesEnum';

export function useHasUserPermission() {
  const { userAuth } = useContext(Context);

  const getUrlWithoutParams = (url: string) => {
    return url.split(':')[0]
  }

  const hasUserPermission = (route: string = '') => {
    if (route && userAuth?.admin && userAuth.type) {
      const permissionsUser = RoutesPermission[userAuth.type]
      return permissionsUser.find(item => route.includes(getUrlWithoutParams(item)))
    }
    return true
  }

  return { hasUserPermission };
}

