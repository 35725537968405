import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { TypeRegister } from 'src/context/hooks/useRegister';

class Teams {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getTeams(branch: string) {
    return this.instance.get('/team', {
      params: { branch },
    } as AxiosRequestConfig);
  }

  register(values: TypeRegister) {
    return this.instance.post('/team', values);
  }

  update(id: string, values: TypeRegister) {
    return this.instance.put(`/team/${id}`, values);
  }
}
const _Teams = (instance: AxiosInstance) => new Teams(instance);

export default _Teams;
