import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { TypeRegister } from 'src/context/hooks/useRegister';
import { IFilterUsers } from '../../context/hooks/useGlobalHooks';

export class Users {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  register(values: TypeRegister) {
    return this.instance.post('/users', values);
  }

  editUser(id: string, values: TypeRegister) {
    return this.instance.put(`/users/${id}`, values);
  }

  deleteUser(id: string) {
    return this.instance.delete(`/users/${id}`);
  }

  getListUsers(filter: IFilterUsers | null) {
    return this.instance.get('/users', {
      params: filter,
    } as AxiosRequestConfig);
  }

  getUser(id: string) {
    return this.instance.get(`/users/${id}`);
  }
}

const user = (instance: AxiosInstance) => new Users(instance);

export default user;
