import { Star } from '@material-ui/icons';
import React from 'react';

interface RatingProps {
  rating: number;
  onChange: (newRating: number) => void;
}

const Rating: React.FC<RatingProps> = ({ onChange, rating }) => {
  return (
    <div>
      {Array.from({ length: 5 }, (_, i) => (
        <Star
          key={i}
          fontSize="medium"
          className="cursor-pointer mx-2"
          style={{ color: i < rating ? '#3FB54B' : 'white' }}
          onClick={() => onChange(i + 1)}
          stroke={rating === 0 ? '#999' : i < rating ? '#3FB54B' : '#88CE8F'}
          strokeWidth={2}
        />
      ))}
    </div>
  );
};

export { Rating };
