import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ISaveServiceDefault } from 'src/context/hooks/useServiceDefault';

class Services {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getRequestsServices(filter: any) {
    return this.instance.get('/global/services', {
      params: filter,
    } as AxiosRequestConfig);
  }

  setNewService(data: any) {
    return this.instance.post('/global/services', data);
  }

  getService(id: any) {
    return this.instance.get(`/global/services/${id}`);
  }

  deleteModel(id: any) {
    return this.instance.delete(`/global/models/${id}`);
  }

  aproveModel(id: any) {
    return this.instance.patch(`/global/models/approved/${id}`);
  }

  serviceDefault(ids: string[]) {
    return this.instance.post(`/global/services/default/list`, { clients: ids });
  }

  saveServiceDefault(data: ISaveServiceDefault) {
    return this.instance.post('/global/services/default', data);
  }

  deleteServiceDefault(id: string) {
    return this.instance.delete(`/global/services/default/${id}`);
  }
}
const _Services = (instance: AxiosInstance) => new Services(instance);

export default _Services;
