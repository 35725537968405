import React, { useContext } from 'react';
import { Context } from 'src/context';

export function useHasEmailPermission() {
  const { userAuth } = useContext(Context);

  const hasEmailPermission = (emailsPermissions: string[] = []) => {
    const userLoggedEmail = userAuth?.email
    return !emailsPermissions?.length || emailsPermissions.some(emailPermission => emailPermission === userLoggedEmail)
  }

  return { hasEmailPermission };
}

