import React, { useContext } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Context } from '../../context';

function ScreenLoading({ message = 'Carregando dados do DataSul...' }: { message?: string }) {
  const { userAuth } = useContext(Context);
  return (
    <div className="w-full h-screen overflow-hidden fixed">
      <div className="m-[40vh] max-w-[300px] ml-[32vw] self-center flex flex-col justify-center">
        <div className="flex justify-center">
          <CircularProgress className="px-auto" />
        </div>
        <div className="text-center self-center mt-12">
          {userAuth?.admin && <h1 className="text-greySada">{message}</h1>}
        </div>
      </div>
    </div>
  );
}

export default ScreenLoading;
