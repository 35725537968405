import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { ChevronRight, ExitToApp } from '@material-ui/icons';
import { Context } from 'src/context';
import { ModalNpsRating } from 'src/components/modal-nps-rating';
import onTrackLogo from 'src/assets/logos/onTrackLogo.svg';
import adminOnTrackLogo from 'src/assets/logos/adminOnTrackLogo.svg';
import logo from 'src/assets/logos/logo.svg';
import useAuth from 'src/context/hooks/useAuth';
import { useOnClickOutside } from 'src/context/hooks/useOnClickOutside';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenterOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import StarOutline from '@material-ui/icons/StarOutline';
import { useHistory } from 'react-router-dom';
import RoutesEnum from 'src/enum/RoutesEnum';
import { STORAGE } from 'src/config/storage';

export const Navbar: React.FC = ({ }) => {
  const history = useHistory();
  const [isBranchSelectOpen, setIsBranchSelectOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isNpsOpen, setNpsOpen] = useState<boolean>(false);

  const selectPdiRef = useRef<any>();
  useOnClickOutside(selectPdiRef, () => setIsBranchSelectOpen(false));

  const selectProfileRef = useRef<any>();
  useOnClickOutside(selectProfileRef, () => setIsProfileMenuOpen(false));

  const { userAuth, availableBranches, selectBranch, setSelectBranch } =
    useContext(Context);

  const { handleLogout } = useAuth()

  useEffect(() => {
    const branchStorage = JSON.parse(localStorage.getItem(STORAGE.SELECTED_BRANCH) || '{}')
    if (branchStorage && branchStorage.id) {
      const branch = availableBranches.find((branch) => branch.id === branchStorage.id)
      setSelectBranch(branch)
    }
  }, [availableBranches]);

  const handleChangeBranch = (id: string) => {
    const branch = availableBranches.find((branch) => branch.id === id);
    if (!branch) return;

    setIsBranchSelectOpen(!isBranchSelectOpen);
    setSelectBranch(branch);
    localStorage.setItem(STORAGE.SELECTED_BRANCH, JSON.stringify(branch));
  };

  const stringToLetter = (string: string) => {
    const splitedString = string.split(' ');
    if (!splitedString[0] || !splitedString[1]) return string[0];

    return `${splitedString[0][0]}${splitedString[1][0]}`.toUpperCase();
  };

  return (
    userAuth &&
    <nav
      className={`px-4 flex items-center justify-between fixed h-16 z-40  border-b-4 w-full ${userAuth?.admin
        ? 'bg-greenSada border-b-darkblueSada'
        : 'bg-darkblueSada border-b-greenSada'
        }`}>
      <div className="flex gap-6 items-center justify-center">
        <img src={userAuth?.admin ? onTrackLogo : adminOnTrackLogo} />
        <span className="h-8 border-black border-[1px] border-opacity-10" />
        <img src={logo} />
      </div>
      <div className="flex gap-5 items-center justify-center relative">
        <div className="flex gap-3 items-center justify-start">
          <div
            className={`${userAuth?.admin ? 'bg-darkblueSada' : 'bg-greenSada'
              } flex items-center justify-center w-8 h-8 rounded-full`}>
            <p className="text-white">{stringToLetter(userAuth?.name || '')}</p>
          </div>
          <div className="flex flex-col items-start justify-center">
            <h5 className="text-white font-medium truncate max-w-[140px]">{userAuth?.name}</h5>
          </div>

          <div ref={selectProfileRef}>

            <ChevronRight
              onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
              className={`text-white cursor-pointer ${isProfileMenuOpen ? 'transform -rotate-90' : 'transform rotate-90'
                }`}
            />

            {isProfileMenuOpen && (
              <div className={`shadow-lg left-0 top-11 bg-white rounded-lg absolute mt-3 z-20 none w-56`}>
                <div className={`hover:bg-[#F5F5F5] flex gap-3 items-center h-10 py-6 pl-3 cursor-pointer`}
                  onClick={() => {
                    history.push(RoutesEnum.VIEW_PROFILE_ROUTE)
                    setIsProfileMenuOpen(false);
                  }}>
                  <PermIdentityOutlinedIcon className="text-greySada" />
                  <p
                    className={`font-medium`}>
                    Perfil
                  </p>
                </div>
                <div className={`hover:bg-[#F5F5F5] flex gap-3 items-center h-10 py-6 pl-3 cursor-pointer`} onClick={() => setNpsOpen(true)}>
                  <StarOutline className="text-greySada" />
                  <p
                    className={`font-medium`}>
                    Avalie o portal!
                  </p>
                </div>
              </div>
            )}
          </div>

        </div>

        <div className="flex gap-3 items-center justify-start relative" ref={selectPdiRef}>
          <span className="relative h-8 border-black border-[1px] border-opacity-10">
            {isBranchSelectOpen && (
              <div
                className={`shadow-lg left-0 top-10 bg-white w-52 rounded-lg absolute mt-3 z-20 none`}>
                <div className={`border-b-[1px] border-b-[##DBDDE2]`}>
                  <p className={`font-medium text-black text-greySada mb-2 p-3 pb-1`}>
                    Selecione o PDI
                  </p>
                </div>
                <RadioGroup
                  aria-label="branch-select"
                  name="branch-select"
                  value={selectBranch?.id}
                  onChange={(event) => {
                    handleChangeBranch(event.target.value);
                  }}>
                  {availableBranches.map((branch) => (
                    <FormControlLabel
                      key={branch.id}
                      color="secondary"
                      className="mb-0 text-black px-3 py-1"
                      value={branch.id}
                      control={<Radio color="primary" />}
                      label={`PDI ${branch.name}`}
                    />
                  ))}
                </RadioGroup>
              </div>
            )}
          </span>
          <BusinessCenterIcon className="text-white" />
          <div className="flex flex-col items-start justify-center">
            <h5 className="text-white font-medium text-sm truncate max-w-[140px]">PDI {selectBranch?.name}</h5>
            <small className="font-normal text-white">Selecione o PDI</small>
          </div>

          <ChevronRight
            onClick={() => setIsBranchSelectOpen(!isBranchSelectOpen)}
            className={`text-white cursor-pointer ${isBranchSelectOpen ? 'transform -rotate-90' : 'transform rotate-90'
              }`}
          />
        </div>

        <span className="h-8 border-black border-[1px] border-opacity-10" />
        <div>
          <ExitToApp className="cursor-pointer text-white" onClick={handleLogout} />
        </div>
      </div>
      <ModalNpsRating open={isNpsOpen} close={() => setNpsOpen(false)} />
    </nav>
  )
};