import React, { createContext, useEffect } from 'react';
import useAuth, { ITypeUserAuthenticated, TypeUser } from './hooks/useAuth';
import useGlobalHooks, { IBranch } from './hooks/useGlobalHooks';
import useAlertGlobal, { IAlert } from './hooks/useAlertGlobal';

export type ContextType = {
  authenticated: boolean;
  loginFailed: string | null;
  setLoginFailed: (values: string | null) => void;
  handleLogin: (values: TypeUser) => Promise<string>;
  concludeAzureLogin: () => Promise<void>;
  userAuth: ITypeUserAuthenticated | null;
  setUserAuth: (values: ITypeUserAuthenticated | null) => void;
  handleCallAlert: (message: string, backgroundColor?: string, active?: boolean) => any;
  alertBox: IAlert | null;
  loadingToken: boolean;
  availableBranches: IBranch[];
  branches: IBranch[];
  selectBranch: any;
  setSelectBranch: (data: any) => void;
};

const Context = createContext({} as ContextType);

function AuthProvider({ children }: any) {
  const { handleCallAlert, alertBox } = useAlertGlobal();

  const {
    authenticated,
    loginFailed,
    handleLogin,
    concludeAzureLogin,
    setLoginFailed,
    userAuth,
    setUserAuth,
    loadingToken,
  } = useAuth();

  const {
    handleGetBranchs,
    selectBranch,
    availableBranches,
    branches,
    setSelectBranch
  } = useGlobalHooks(userAuth);

  useEffect(() => {
    if (authenticated) {
      handleGetBranchs();
    }
  }, [userAuth, authenticated]);

  return (
    <Context.Provider
      value={{
        authenticated,
        loginFailed,
        setLoginFailed,
        handleLogin,
        concludeAzureLogin,
        userAuth,
        setUserAuth,
        handleCallAlert,
        alertBox,
        loadingToken,
        selectBranch,
        availableBranches,
        branches,
        setSelectBranch
      }}>
      {children}
    </Context.Provider>
  );
}

export { Context, AuthProvider };
