import React, { useContext } from 'react';
import RoutesEnum from 'src/enum/RoutesEnum';
import { Context } from 'src/context';
import { history } from '../../App';
import ScreenLoading from '../ScreenLoading';
import { useHasUserPermission } from 'src/context/hooks/useHasUserPermission';
import { useLocation } from 'react-router-dom';

function AdminGuard({ children }: any) {
  const { userAuth } = useContext(Context);
  const { hasUserPermission } = useHasUserPermission();
  const location = useLocation();

  if (!userAuth) return <ScreenLoading />;
  if (!userAuth?.admin) return history.push(RoutesEnum.ADMIN_DASHBOARD_ROUTE);
  if (!hasUserPermission(location.pathname)) return history.push(RoutesEnum.ADMIN_DASHBOARD_ROUTE);
  return children;
}

export default AdminGuard;
