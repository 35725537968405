import React from 'react';
import { useFormikContext } from 'formik';
import { Box, TextField, Typography } from '@material-ui/core';
import { NpsForm } from '../../types';

function NpsComment() {
  const { values, setFieldValue } = useFormikContext<NpsForm>();

  return (
    <Box className="flex flex-col gap-2 items-start">
      <Typography variant="subtitle2" className="font-medium">
        Deixe seu comentário
      </Typography>
      <TextField
        id="outlined-multiline-flexible"
        label="Descreva aqui"
        multiline
        variant="outlined"
        maxRows={3}
        minRows={3}
        fullWidth
        value={values.comment}
        onChange={(e) => setFieldValue('comment', e.target.value)}
      />
    </Box>
  );
}

export { NpsComment };
