import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import RoutesEnum from 'src/enum/RoutesEnum';

export const noAuthGuardRoutes = [
  {
    exact: true,
    path: RoutesEnum.INDEX_ROUTE,
    component: () => <Redirect to={RoutesEnum.DASHBOARD_ROUTE} />,
  },
  {
    exact: true,
    path: RoutesEnum.LOGIN_ROUTE,
    component: lazy(() => import('src/pages/login')),
  },
  {
    exact: true,
    path: RoutesEnum.REGISTER_ROUTE,
    component: lazy(() => import('src/pages/register')),
  },
  {
    exact: true,
    path: RoutesEnum.RECOVER_PASSWORD_ROUTE,
    component: lazy(() => import('src/pages/recoverPassword')),
  },
  {
    exact: true,
    path: RoutesEnum.NEW_PASSWORD_ROUTE,
    component: lazy(() => import('src/pages/newPassword')),
  },
]