import React, { useContext } from 'react';
import Fade from '@material-ui/core/Fade';
import { Context } from 'src/context';

export default function AlertBoxGlobal() {
  const { alertBox } = useContext(Context);

  const styledAlert = {
    top: '85%',
    left: '75%',
    color: '#FFFFFFDE',
    backgroundColor: alertBox?.backgroundColor || '#2687E9',
    padding: 15,
    paddingRight: 25,
    width: 330,
    minHeight: 48,
    borderRadius: 8,
    fontSize: 14,
    boxShadow: '10px 10px 29px -15px rgba(0,0,0,0.75)',
    zIndex: 9999,
  };

  if (!alertBox?.active) return null;

  return (
    <Fade in={alertBox?.active}>
      <div className="fixed shadow-lg" style={styledAlert}>
        <p className="text-center break-words max-w-[300px]" data-cy="alert-box">
          {alertBox.message}
        </p>
      </div>
    </Fade>
  );
}
