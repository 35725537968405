import { useEffect, useState, useCallback, useContext } from 'react';
import api from 'src/services';
import { history } from 'src/App';
import RoutesEnum from 'src/enum/RoutesEnum';
import * as Sentry from '@sentry/react';
import TypeAccessUser from '@/src/enum/TypeAccessUser';
import { Context } from '..';
import TypeUserAuth from 'src/enum/TypeUser';
import { STORAGE } from 'src/config/storage';

export interface TypeUser {
  email: string | ObjectConstructor;
  password: string | ObjectConstructor;
}

export interface ITypeUserAuthenticated {
  name: string | undefined;
  id: string;
  email: string | undefined;
  admin?: boolean;
  phone?: string;
  created_at: string;
  updated_at: string;
  type_access?: TypeAccessUser;
  type?: TypeUserAuth
}

export interface ITypeUpdateUser {
  name: string;
  email: string;
  phone: string;
  password: string | null;
  newpassword: string | null;
  newpassword_confirm: string | null;
}

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState<boolean>(() => {
    const token = localStorage.getItem(STORAGE.TOKEN);
    return !!token;
  });
  const [userAuth, setUserAuth] = useState<ITypeUserAuthenticated | null>(null);
  const [loginFailed, setLoginFailed] = useState<string | null>(null);
  const [loadingToken, setLoadingToken] = useState<boolean>(false);
  const { setSelectBranch } = useContext(Context);


  const validateToken = useCallback(() => {
    setLoadingToken(true);
    api
      .auth()
      .verifyExpired()
      .then((response) => {
        setUserAuth(response.data.user);
        setAuthenticated(true);
      })
      .catch((err: any) => {
        setAuthenticated(false);
        Sentry.captureException(err);
      })
      .finally(() => {
        setLoadingToken(false);
      });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(STORAGE.TOKEN);
    const atoken = localStorage.getItem(STORAGE.TOKEN_ADMIN);
    if (token) {
      api.instance.defaults.headers.Authorization = `Bearer ${token}`;
      api.instance.defaults.headers.AuthorizationAdmin = `Bearer ${atoken}`;
    }
    validateToken();
  }, [validateToken]);

  async function concludeAzureLogin(): Promise<void> {
    localStorage.setItem(STORAGE.TOKEN, localStorage.getItem('@pdiOnline-azure-token') || '');
    localStorage.setItem(
      STORAGE.TOKEN_ADMIN,
      localStorage.getItem('@pdiOnline-azure-atoken') || '',
    );
    localStorage.setItem(STORAGE.USER_DATA, localStorage.getItem('@pdiOnline-azure-user') || '');
    api.instance.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
      '@pdiOnline-azure-token',
    )}`;
    api.instance.defaults.headers.AuthorizationAdmin = `Bearer ${localStorage.getItem(
      '@pdiOnline-azure-atoken',
    )}`;
    const tempUser = JSON.parse(localStorage.getItem('@pdiOnline-azure-user') || '{}');
    setUserAuth(tempUser as ITypeUserAuthenticated);
    setAuthenticated(true);
    history.push(RoutesEnum.USERS_ROUTE);
    validateToken();
  }

  async function handleLogin(values: TypeUser): Promise<string> {
    setLoginFailed(null);
    try {
      const { data } = await api.auth().signIn(values);
      if (String(values.email).includes('sada.com') && !!process.env.REACT_APP_AZURE) {
        localStorage.setItem('@pdiOnline-azure-token', data.token || '');
        localStorage.setItem('@pdiOnline-azure-atoken', data.atoken || '');
        localStorage.setItem('@pdiOnline-azure-user', JSON.stringify(data.user) || '');
        localStorage.setItem('@pdiOnline-azure-timeout', new Date().toISOString());
        return 'azure';
      }
      localStorage.setItem(STORAGE.TOKEN, data.token || '');
      localStorage.setItem(STORAGE.TOKEN_ADMIN, data.atoken || '');
      localStorage.setItem(STORAGE.REFRESH, data.refreshToken || '');
      localStorage.setItem(STORAGE.USER_DATA, JSON.stringify(data.user) || '');
      api.instance.defaults.headers.Authorization = `Bearer ${data.token}`;
      api.instance.defaults.headers.AuthorizationAdmin = `Bearer ${data.atoken}`;
      setUserAuth(data.user as ITypeUserAuthenticated);
      setAuthenticated(true);
      history.push(RoutesEnum.USERS_ROUTE);
      validateToken();
      return 'success';
    } catch (err) {
      setLoginFailed('Usuário ou senha incorretos. Tente novamente.');
      return 'failed';
    }
  }

  async function handleLogout(): Promise<void> {
    setAuthenticated(false);
    setUserAuth(null)
    setSelectBranch(null)
    localStorage.removeItem(STORAGE.TOKEN);
    localStorage.removeItem(STORAGE.TOKEN_ADMIN);
    localStorage.removeItem(STORAGE.REFRESH);
    localStorage.removeItem(STORAGE.USER_DATA);
    localStorage.removeItem('@pdiOnline-azure-user');
    localStorage.removeItem('@pdiOnline-azure-atoken');
    localStorage.removeItem('@pdiOnline-azure-user');
    history.push(RoutesEnum.LOGIN_ROUTE);
  }

  return {
    authenticated,
    loginFailed,
    setLoginFailed,
    handleLogin,
    handleLogout,
    userAuth,
    setUserAuth,
    loadingToken,
    concludeAzureLogin,
  };
}
