import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { IFilterFilesVehicles } from 'src/context/hooks/useFileRequest';
import { IFilterRequest } from 'src/context/hooks/useListRequest';
import { ITypeNewRequest } from '../../context/hooks/useRequest';

interface IFilterCargaAndArquivedStatus {
  client?: string;
}

export class Requests {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getRequests(filter?: IFilterRequest | undefined) {
    return this.instance.get('/request', {
      params: filter,
    } as AxiosRequestConfig);
  }

  getRequest(id: string) {
    return this.instance.get(`/request/${id}`);
  }

  getRequestsFilesTable(idRequest: string, filter?: IFilterFilesVehicles) {
    return this.instance.get(`request/${idRequest}/files`, {
      params: filter,
    } as AxiosRequestConfig);
  }

	setNewFileRequest(idRequest: string, data: any) {
		return this.instance.patch(`request/${idRequest}/files`, data)
	}

  deleteVehicle(idRequest: string, idVehicle: string) {
    return this.instance.delete(`/request/${idRequest}/vehicles/${idVehicle}`);
  }

  newRequestPDI(values: ITypeNewRequest) {
    return this.instance.post('/request', values);
  }

	setStatusPendingIssues(idRequest: string, data: {status: number, vehicles: string[]}) {
        return this.instance.patch(`/request/${idRequest}/status`, data);
	}

  deleteFile(idRequest: string, idFile: string) {
    return this.instance.delete(`/request/${idRequest}/files/${idFile}`);
  }

  getVehiclesStatusCarga(filter: IFilterCargaAndArquivedStatus) {
    return this.instance.get('/vehicles/finalizado', {
      params: filter,
    } as AxiosRequestConfig);
  }

  vehicleArquived(values: any) {
    return this.instance.post('/vehicles/arquivado', values);
  }

  getVehiclesStatusArquived(filter: IFilterCargaAndArquivedStatus) {
    return this.instance.get('/vehicles/arquivado', {
      params: filter,
    } as AxiosRequestConfig);
  }

  getVehiclesDispatched(filter: IFilterCargaAndArquivedStatus) {
    return this.instance.get('/vehicles/expedido', {
      params: filter,
    } as AxiosRequestConfig);
  }

  getVehiclesDownload(idRequest: string) {
    // @ts-ignore
    return this.instance.get(`/request/${idRequest}/download-daily`, { responseType: 'blob' });
  }
}

const _Requests = (instance: AxiosInstance) => new Requests(instance);

export default _Requests;
