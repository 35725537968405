import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { IFilterRequest } from 'src/context/hooks/useListRequest';

class RequestsSada {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getRequests(filter?: IFilterRequest | undefined) {
    return this.instance.get('/admin/request', {
      params: filter,
    } as AxiosRequestConfig);
  }

  getRequest(id: string) {
    return this.instance.get(`/admin/request/${id}`);
  }
}
const _RequestsSada = (instance: AxiosInstance) => new RequestsSada(instance);

export default _RequestsSada;
