import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { NpsForm } from '../../types';
import { Rating } from '../rating';

function NpsRate() {
  const { values, setFieldValue } = useFormikContext<NpsForm>();

  return (
    <Box className="flex flex-col gap-4">
      <Box>
        <Typography variant="h6" className="font-medium">
          Como você avalia sua experiência no portal?
        </Typography>
        <Typography variant="subtitle1">
          Clique em uma ou mais estrelas para avaliar
        </Typography>
      </Box>
      <Rating
        rating={values.rating}
        onChange={(newRating: number) => setFieldValue('rating', newRating)}
      />
    </Box>
  );
}

export { NpsRate };
