import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Box, DialogTitle, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { NpsForm, NpsModalProps, Step } from './types';
import { NpsRate } from './components/nps-rate';
import { NpsComment } from './components/nps-comment';
import { NpsSuccess } from './components/nps-success';
import { sendNpsMeasurement, sendNpsTracking } from 'src/services/v2/nps';

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);

enum Steps {
  RATE = 0,
  COMMENT = 1,
  SUCCESS = 2,
}

const ModalNpsRating: React.FC<NpsModalProps> = ({ open, close }) => {
  const [step, setStep] = useState(Steps.RATE);

  const handleClose = () => {
    setStep(Steps.RATE);
    close();
  };

  async function sendTracking() {
    try {
      await sendNpsTracking();
    } catch (err) {
      handleClose();
    }
  }

  useEffect(() => {
    if (open) sendTracking();
  }, [open]);

  const handleNext = (submitForm: () => void, resetForm: () => void) => {
    if (step === Steps.COMMENT) {
      submitForm();
    }

    if (step === Steps.SUCCESS) {
      resetForm();
      setStep(Steps.RATE);
      handleClose();

      return;
    }

    setStep(step + 1);
  };

  const handleBack = () => setStep(step - 1);

  const checkIfDisabled = (values: NpsForm, step: number) => {
    if (step === Steps.RATE) {
      return !values.rating;
    }

    return false;
  };

  const steps: Step[] = [
    { name: 'rate', component: <NpsRate /> },
    { name: 'comment', component: <NpsComment /> },
    { name: 'success', component: <NpsSuccess /> },
  ];

  const currentStep = steps[step];

  return (
    <Formik
      initialValues={{ rating: 0, comment: '' } as NpsForm}
      onSubmit={async function (values: NpsForm): Promise<any> {
        try {
          await sendNpsMeasurement(values);
        } catch (err) {}
      }}>
      {({ values, submitForm, resetForm }: FormikProps<NpsForm>) => (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          data-testid="mdal-duplicate-vehicles-container"
          maxWidth="md">
          <DialogTitle>
            <Box className="flex flex-row items-center justify-between">
              <Typography variant="h6" className="font-medium">
                Pesquisa de satisfação
              </Typography>
              <Close
                onClick={handleClose}
                data-testid="modal-disapprove-close-icon"
                className="cursor-pointer"
              />
            </Box>
          </DialogTitle>
          <DialogContent className="flex flex-col justify-center px-8 w-[580px] h-[140px]">
            <Form>{currentStep && currentStep.component}</Form>
          </DialogContent>
          <DialogActions
            style={{
              padding: '1.75rem',
              justifyContent: step === Steps.COMMENT ? 'space-between' : 'flex-end',
              display: 'flex',
            }}>
            <>
              {step === Steps.COMMENT && (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleBack}
                  id="remove-chassis"
                  aria-labelledby="remove-chassis">
                  Voltar
                </Button>
              )}
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleNext(submitForm, resetForm)}
                disabled={checkIfDisabled(values, step)}
                id="remove-chassis"
                aria-labelledby="remove-chassis">
                {step === Steps.SUCCESS ? 'Ok!' : 'Próximo'}
              </Button>
            </>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export { ModalNpsRating };
