import React from 'react';
import { CircularProgress } from '@material-ui/core';

export default function SplashScreen() {
  return (
    <div className="h-screen w-screen flex justify-center overflow-hidden fixed">
      <div className="self-center">
        <CircularProgress />
      </div>
    </div>
  );
}
