import { STORAGE } from 'src/config/storage';
import ReactGA from 'react-ga4'


export const inititalizeAnalytics = (): void => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || 'G-72MZ2GNKGF')
  if (localStorage.getItem(STORAGE.USER_DATA)) {
    const userData = JSON.parse(localStorage.getItem(STORAGE.USER_DATA) || '{}');
    ReactGA.ga('set', 'userId', userData?.id);
  }
}

export const sendEventAnalytics = (hitType: string, page: string, params: any = {}): void => {
  if (localStorage.getItem(STORAGE.USER_DATA)) {
    const userData = JSON.parse(localStorage.getItem(STORAGE.USER_DATA) || '{}');
    params.user_id_pdi = userData?.id
    params.user_email_pdi = userData?.email
  }
  ReactGA.send({
    hitType,
    page,
    ...params
  });
};