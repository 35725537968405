import { AxiosInstance } from 'axios';
import { TypeRecoverPassword, TypeResetPassword } from 'src/context/hooks/useRecoverPassword';
import { ITypeUpdateUser } from '../../context/hooks/useAuth';

interface ISignInProps {
  password: string | ObjectConstructor;
  email: string | ObjectConstructor;
}

interface IResponseSignIn {
  user: object;
  token: string;
  atoken: string;
  refreshToken: string;
}

export class Auth {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  signIn({ password, email }: ISignInProps) {
    return this.instance.post<IResponseSignIn>('/session', { password, email });
  }

  forgot(values: TypeRecoverPassword) {
    return this.instance.post('/password/forgot', values);
  }

  reset(values: TypeResetPassword) {
    return this.instance.post('/password/reset', values);
  }

  verifyExpired() {
    return this.instance.get('/session');
  }

  refreshToken(token: string) {
    return this.instance.get(`/session/refresh-token/${token}`);
  }

  updateUser(values: ITypeUpdateUser) {
    return this.instance.put('/session', values);
  }

  validatePortalEvaluationPeriod() {
    return this.instance.get(`/session/validate-portal-evaluation-period`);
  }
}

const auth = (instance: AxiosInstance) => new Auth(instance);

export default auth;
