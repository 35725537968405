import { useCallback, useState } from 'react';
import api from 'src/services';
import { IRequestVehicleData } from './useListRequest';

export default function useServicesContext() {
  const [requestVehicleData, setRequestVehicleData] = useState<IRequestVehicleData>({
    vehicles: [],
    services: [],
    editData: {},
  });
  const [loadingEditVehicle, setLoadingEditVehicle] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const saveVehicleData = (vehicles: []) => {
    setRequestVehicleData((old) => ({ ...old, vehicles }));
  };

  const saveServicesData = useCallback((services: any[]) => {
    setRequestVehicleData((old) => ({ ...old, services }));
  }, []);

  const resetRequestVehiclesData = useCallback(async (edit: any) => {
    setLoadingEditVehicle(true);
    try {
      let editData = null;
      if (edit) {
        const response: any = await api.vehicles().getVehicle(edit.request_id, edit.id);
        editData = response.data;
      }
      setRequestVehicleData({ vehicles: [], services: [], editData });
    } catch (err) {
      alert(err);
    } finally {
      setLoadingEditVehicle(false);
    }
  }, []);

  return {
    saveVehicleData,
    saveServicesData,
    requestVehicleData,
    loadingEditVehicle,
    resetRequestVehiclesData,
    isEdit,
    setIsEdit,
  };
}
