import { AxiosInstance } from 'axios';

class Logs {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getLogs(idRequest: string) {
    return this.instance.get(`/request/${idRequest}/logs`);
  }
}

const _Logs = (instance: AxiosInstance) => new Logs(instance);

export default _Logs;
